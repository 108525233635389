import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Carousel, Row, Tab, Nav } from "react-bootstrap"
import "../dist/css/style.css"

import close from "../images/close2.png"

import t1 from "../images/people/board-01.jpg"
import t2 from "../images/people/board-02.jpg"
import t3 from "../images/people/board-03.jpg"
import t4 from "../images/people/board-04.jpg"
import t5 from "../images/people/board-05.jpg"
import t6 from "../images/people/board-06.jpg"
import t7 from "../images/people/board-07.jpg"
import t8 from "../images/people/board-08.jpg"
import t9 from "../images/people/board-09.jpg"
import t11 from "../images/people/board-11.jpg"
import t12 from "../images/people/board-12.jpg"
import t13 from "../images/people/board-13.jpg"
import t14 from "../images/people/board-14.jpg"
import t15 from "../images/people/board-15.jpg"
import t16 from "../images/people/board-16.jpg"
import t17 from "../images/people/board-17.jpg"
import t18 from "../images/people/board-18.jpg"
import t19 from "../images/people/board-19.jpg"
import t20 from "../images/people/board-20.jpg"
import t21 from "../images/people/board-21.jpg"
import t22 from "../images/people/board-22.jpg"
import t23 from "../images/people/board-23.jpg"
import t24 from "../images/people/board-24.jpg"
import t25 from "../images/people/board-25.jpg"
import t26 from "../images/people/board-26.jpg"
import t27 from "../images/people/board-27.jpg"
import swethaImage from "../images/people/swetha.png"
import paragImage from "../images/people/parag.png"

import b2 from "../images/people/Board/boardMember/b2.jpg"
import b3 from "../images/people/Board/boardMember/b3.jpg"
import b4 from "../images/people/Board/boardMember/b4.jpg"
import b5 from "../images/people/Board/boardMember/b5.jpg"
import b6 from "../images/people/Board/boardMember/b6.jpg"
import b7 from "../images/people/Board/boardMember/b7.jpg"

import ab1 from "../images/people/Board/advisoryBoard/ab1.png"
import ab2 from "../images/people/Board/advisoryBoard/ab2.jpg"
import ab3 from "../images/people/Board/advisoryBoard/ab3.jpg"
import ab4 from "../images/people/Board/advisoryBoard/ab4.png"
import ab5 from "../images/people/Board/advisoryBoard/ab5.jpg"
import ab6 from "../images/people/Board/advisoryBoard/ab6.jpg"
import ab7 from "../images/people/Board/advisoryBoard/ab7.jpg"
import ab8 from "../images/people/Board/advisoryBoard/ab8.png"
import ab9 from "../images/people/Board/advisoryBoard/ab9.jpg"
import ab10 from "../images/people/Board/advisoryBoard/ab10.jpg"

import ban1 from "../images/people/Regional/ban/b1.jpg"
import ban2 from "../images/people/Regional/ban/b2.jpg"
import ban3 from "../images/people/Regional/ban/b3.jpg"
import ban4 from "../images/people/Regional/ban/b4.jpg"
import ban5 from "../images/people/Regional/ban/b5.jpg"
import ban6 from "../images/people/Regional/ban/b6.jpg"
import ban7 from "../images/people/Regional/ban/b7.jpg"

import del1 from "../images/people/Regional/del/b1.jpg"
import del2 from "../images/people/Regional/del/b2.jpg"
import del3 from "../images/people/Regional/del/b3.jpg"
import del4 from "../images/people/Regional/del/b4.jpg"
import del5 from "../images/people/Regional/del/b5.jpg"
import del6 from "../images/people/Regional/del/b6.png"
import del7 from "../images/people/Regional/del/b7.jpg"

import pun1 from "../images/people/Regional/pun/b1.jpg"
import pun2 from "../images/people/Regional/pun/b2.jpg"
import pun3 from "../images/people/Regional/pun/b3.jpg"
import pun4 from "../images/people/Regional/pun/b4.jpg"
import pun5 from "../images/people/Regional/pun/b5.jpg"
import pun6 from "../images/people/Regional/pun/b6.jpg"
import pun7 from "../images/people/Regional/pun/b7.jpg"
import pun8 from "../images/people/Regional/pun/b8.jpg"

import twitter from "../images/people/twitter.png"
import linkedin from "../images/people/linkedin.png"

const OurPeople = () => {
  const [innMod2, setInnMod2] = useState()
  const [innModimg, setInnModimg] = useState()
  const [innModTitle, setinnModTitle] = useState()
  const [innModdesig, setInnModdesig] = useState()
  const [innModpara, setinnModpara] = useState()
  const [innLinked, setinnLinked] = useState()
  const [innTweet, setinnTweet] = useState()
  return (
    <Layout pageName="Our People">
      <SEO title="Our People" />
      <Container className="posrel text-center my-5">
        <p className="ed-title">Our People</p>
        <p className="jtf-head">
          Meet the minds behind
          <br />
          and with the movement
        </p>
      </Container>

      <Container>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row className="tabContaier peoples">
            <div className="col-md-12 px-0">
              <Nav className="tb">
                <Nav.Item>
                  <Nav.Link eventKey="first">The Team</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">The Board</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">The Regional Board</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Row>

          <div className="col-md-12 px-0">
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Row>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("SHAHEEN MISTRI")
                      setInnModimg(t1)
                      setInnModdesig("CEO AND FOUNDER TRUSTEE")
                      setinnModpara(
                        "Shaheen grew up in 5 countries around the world, and returned to India when she was 18 to start Akanksha. For 17 years, she worked with teachers and students, building Akanksha to provide children from low- income communities the kind of education that would maximize their greatest potential. Today, Akanksha serves 9300 children through their School Project and after-school centres in Mumbai and Pune. In 2008, Shaheen founded Teach For India, with an audacious vision of providing an excellent education to all children across India through building a pipeline of leaders committed to ending educational inequity in India. Today, Teach For India directly impacts approximately 32,000 children across seven regions in India. Shaheen has also created projects like the Maya Musical and the Kid’s Education Revolution which explore student leadership, platforms for student voice and partnership, and TFIx, which is a year-long incubator program for passionate entrepreneurs who are willing to adapt Teach For India’s model to their context and region in rural areas. Shaheen serves on the boards of the Akanksha Foundation, and Simple Education Foundation. Shaheen has been an Ashoka Fellow, a Global Leader for Tomorrow at the World Economic Forum, and an Asia Society 21 Leader. She is the author of the book, Redrawing India and the Miss Muglee Children’s Books. She has a Bachelor’s Degree from St. Xavier’s College, Mumbai and a Master’s Degree from the University of Manchester."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/shaheen-mistri/"
                      )
                      setinnTweet("https://twitter.com/shaheenmistri")
                    }}
                  >
                    <img src={t1} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">SHAHEEN MISTRI</p>
                    <p className="gentxt">CEO AND FOUNDER TRUSTEE</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("SANDEEP RAI")
                      setInnModimg(t3)
                      setInnModdesig("CHIEF OF CITY OPERATIONS")
                      setinnModpara(
                        "Sandeep came to Teach For India in 2009 after having worked as a fundraising consultant with Ashoka and teaching secondary science in Washington DC, as a Teach For America corps member. His experience in the classroom deepened his resolve to contribute to the educational reform movement and motivated by the same - he has led the creation and implementation of Teach For India’s first Institute. Sandeep has also worked in a number of different capacities across Program, City Operations and Knowledge. He has a Master’s Degree in International Affairs and Economic Policy from Columbia University, New York."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/sandeep-rai-3463a0a/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t3} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">SANDEEP RAI</p>
                    <p className="gentxt">CHIEF OF CITY OPERATIONS</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("MANASI JAIN")
                      setInnModimg(t4)
                      setInnModdesig("CHIEF OF STAFF")
                      setinnModpara(
                        "Manasi is a management & computer science professional with a desire to maximize social impact by solving business problems using technology. She has experience across consulting, market research and education. Post her MBA from the Indian Institute of Foreign Trade, Delhi, she worked as a Business Consultant for Cognizant Business Consulting for 2 years. With a desire to use her abilities to solve bigger and more urgent problems, she joined the Teach For India Fellowship in 2017, teaching grade 10 Students in Ghatkopar and Dharavi, Mumbai. She holds a Level 3 certification in Japanese language proficiency and is currently preparing for Level 2."
                      )
                      setinnLinked("https://www.linkedin.com/in/manasijain")
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t4} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">MANASI JAIN</p>
                    <p className="gentxt">CHIEF OF STAFF</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("TANYA ARORA")
                      setInnModimg(t5)
                      setInnModdesig("DIRECTOR, HUMAN RESOURCES")
                      setinnModpara(
                        "Tanya moved back to India after graduating from Franklin and Marshall College, Pennsylvania, with a degree in Psychology. Inspired by the movement, she joined Teach For India’s team as a Recruitment Associate. She subsequently managed the organisation’s recruitment efforts for its West, East and International portfolios, before transitioning to lead the organisation's Selection process. Tanya is committed to ensuring that the best candidates are brought on board the program – those who will transform the lives of their Students and will strive to put them on a different life path."
                      )
                      setinnLinked("https://www.linkedin.com/in/tanyaarora/")
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t5} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">TANYA ARORA</p>
                    <p className="gentxt">DIRECTOR, HUMAN RESOURCES</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("HITESH RAWTANI")
                      setInnModimg(t6)
                      setInnModdesig("DIRECTOR, TECHNOLOGY")
                      setinnModpara(
                        "Hitesh has over sixteen years of experience in delivering technology solutions across multiple domains. He joined Teach For India to use his expertise in technology towards achieving the vision of educational equity in India. In the past, he has worked with organisations such as Mastek, Morgan Stanley and Queppelin - a technology startup where he built a 20-member team in less than two years. He holds a Bachelor's degree in Information Technology from Mumbai University."
                      )
                      setinnLinked("https://www.linkedin.com/in/hiteshrawtani/")
                      setinnTweet("https://twitter.com/HiteshRawtani")
                    }}
                  >
                    <img src={t6} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">HITESH RAWTANI</p>
                    <p className="gentxt">DIRECTOR, TECHNOLOGY</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ALPANA MALLICK")
                      setInnModimg(t8)
                      setInnModdesig("DIRECTOR, TRAINING & IMPACT")
                      setinnModpara(
                        "Alpana is the Director of Training & Impact at Teach For India. She started her career as a copywriter and content creator for online media. a 2011 Teach For India Fellow, she taught 64 5th graders in Mumbai, and 27 3rd Graders in Chennai. Post the Fellowship, she joined Staff as the Training Manager for Literacy in 2013, and for the past 6 years has worked on different portfolios aimed at Student, Fellow, and Program Staff development. In 2016, she completed the Systems Leadership Accelerator Program with Teach For India Alumni. She is currently a 2018 Fellow in the Conscious Leadership & Transformation Fellowship by the Teach for All Network."
                      )
                      setinnLinked("https://www.linkedin.com/in/alpanamallick")
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t8} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ALPANA MALLICK</p>
                    <p className="gentxt">DIRECTOR, TRAINING & IMPACT</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("SARA KHAN")
                      setInnModimg(t9)
                      setInnModdesig("DIRECTOR, DEVELOPMENT")
                      setinnModpara(
                        "Sara brings over sixteen years of diverse experience in banking, management, marketing and partnerships, across HSBC, HDFC Bank and now Teach For India. She holds a Bachelor's degree in Engineering and a post graduate management diploma. Sara seeks to build lifelong champions of the cause and acquire financial and non-financial support for the movement."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/sangeetayadav01/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t9} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">SARA KHAN</p>
                    <p className="gentxt">DIRECTOR, DEVELOPMENT</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ABHIK BHATTACHERJI")
                      setInnModimg(t11)
                      setInnModdesig("DIRECTOR, MARKETING AND COMMUNICATION")
                      setinnModpara(
                        "Abhik Bhattacherji is a literature major from St. Stephen’s College, New Delhi. He has 12 years of experience across the Corporate and Development sector. He started his career as a Corporate Communications Executive at Britannia Industries before becoming a Fellow in the inaugural cohort of Teach For India, in 2009. He’s worked at Godrej in the Design and Innovation Lab, hosted Philanthropy Weeks at Dasra, taught at a Boys Boarding School in Dehradun and consulted at Teach For Bangladesh and Teach For Slovakia."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/abhik-bhattacherji-84442b115/"
                      )
                      setinnTweet("https://twitter.com/abhikbee?lang=en")
                    }}
                  >
                    <img src={t11} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">
                      ABHIK BHATTACHERJI
                    </p>
                    <p className="gentxt">
                      DIRECTOR, MARKETING AND COMMUNICATION
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ALOMA REGO")
                      setInnModimg(t12)
                      setInnModdesig("DIRECTOR, FELLOWSHIP SELECTION")
                      setinnModpara(
                        "Aloma leads the Fellowship Selection team at Teach For India. She has been a part of the Selection team for 6 years and has led multiple roles on the team ensuring that we find the best candidates to carry Teach For India's vision and mission forward. She also has experience of working at Make A Difference as the Director for Shelter Operations and IMRB International as a Research Manager. Aloma holds degrees across business administration, human rights, psychology and corporate social responsibility. From her experiences, Aloma brings with her operational excellence, research skills and a deep belief in the power of holistic education. Through her work of finding the next generation of leaders, she is a firm believer that we should all work towards being better than we were yesterday and also leave the world a better place than we found it."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/aloma-rego-968aa024/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t12} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ALOMA REGO</p>
                    <p className="gentxt">DIRECTOR, FELLOWSHIP SELECTION</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ADITYA MALLYA")
                      setInnModimg(t13)
                      setInnModdesig("CITY DIRECTOR, MUMBAI")
                      setinnModpara(
                        "Aditya leads the City Operations team at Teach For India in Mumbai. He joined the organisation in 2016, prior to which he worked for three years as a Management Consultant in Deloitte in the Consulting Strategy & Operations division, primarily advising clients in supply chain and manufacturing operations. He also has two years of operational experience in the FMCG manufacturing sector, having worked for the Personal Care Products division at ITC Limited. Aditya holds degrees from the National Institute of Technology, Jalandhar and the Indian School of Business."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/mwlite/in/aditya-mallya-10996394"
                      )
                      setinnTweet("https://twitter.com/admallya?s=20")
                    }}
                  >
                    <img src={t13} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ADITYA MALLYA</p>
                    <p className="gentxt">CITY DIRECTOR, MUMBAI</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("NALIKA BRAGANZA ")
                      setInnModimg(t14)
                      setInnModdesig("PROGRAM DIRECTOR, TRAINING & IMPACT")
                      setinnModpara(
                        "Nalika graduated from Sophia College for Women in 2012 with a degree in English Literature. During her time in Mumbai, she was struck by the stark inequity that she saw in every corner of Mumbai. She decided to apply to the Teach For India Fellowship and taught a beautiful bunch of 3rd and 4th grade Students in Kala Chowky, Mumbai. The two years in the Fellowship gave her hope that change is possible and she graduated the Fellowship wanting to be of service to as many children as possible. She joined the Program Staff team, where she coached and managed a group of Fellows for two years and then transitioned to lead the Program Team in the city for the next four years. Over her time at Teach For India, she has helped train and support close to 2000 teachers who have impacted 10,000 students in the city of Mumbai and beyond."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t14} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">NALIKA BRAGANZA </p>
                    <p className="gentxt">
                      PROGRAM DIRECTOR, TRAINING & IMPACT
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("MANSI JOSHI")
                      setInnModimg(t15)
                      setInnModdesig("CITY DIRECTOR, DELHI")
                      setinnModpara(
                        "Mansi is currently leading the City Operations team at Teach For India in Delhi. She has completed her Master’s in Business Administration from the Indian School of Business and holds an Economics (H) degree from the University of Delhi. Prior to this, she has worked as a Senior Program Manager and Program Manager at Teach For India, Delhi. Her work involved designing Strategy for the Delhi Program and driving implementation by working with the Program Team to uphold Fellow, Student and Staff development across the city. Mansi was a 2012 Fellow and taught 40 girls in grade 2 and 3 in Majnu Ka Tila in Delhi."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/mansi-joshi-ab816174"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t15} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">MANSI JOSHI</p>
                    <p className="gentxt">CITY DIRECTOR, DELHI</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("DAKSHINAMOORTHY VISWESWARAN")
                      setInnModimg(t16)
                      setInnModdesig("CITY DIRECTOR, CHENNAI")
                      setinnModpara(
                        "A Teach For India Alumnus, Dakshinamoorthy, did the Fellowship from 2014-2016. He currently leads City Operations, in Chennai . He is a strong believer in the individual potential of every child and collective consciousness of adults in helping children realise their potential. He has previously worked with Mahindra & Mahindra Limited and Thermax Foundation. He has been in the social development sector since 2014 and has experience in leading teams in teacher training, program design, government partnerships and Alumni engagement."
                      )
                      setinnLinked(
                        "https://in.linkedin.com/in/dakshinamoorthy-visweswaran-88111041"
                      )
                      setinnTweet("https://twitter.com/dm_moorthy")
                    }}
                  >
                    <img src={t16} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">
                      DAKSHINAMOORTHY VISWESWARAN
                    </p>
                    <p className="gentxt">CITY DIRECTOR, CHENNAI</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("TULIKA VERMA")
                      setInnModimg(t17)
                      setInnModdesig("CITY DIRECTOR, BANGALORE")
                      setinnModpara(
                        "Tulika took over city leadership for Bangalore with a deep belief in the collective power and potential of committed, passionate individuals. Prior to Teach For India, she has worked with Philips Healthcare across healthcare product portfolios, and has done a brief stint leading a state government innovation project. She has an MBA from IIM, Ahmedabad and a B.Tech from Manipal Institute of Technology."
                      )
                      setinnLinked("https://www.linkedin.com/in/tulika-verma/")
                      setinnTweet("https://twitter.com/tulipinquest")
                    }}
                  >
                    <img src={t17} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">TULIKA VERMA</p>
                    <p className="gentxt">CITY DIRECTOR, BANGALORE</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("KESHAR MOKHA")
                      setInnModimg(t18)
                      setInnModdesig("CITY DIRECTOR, PUNE")
                      setinnModpara(
                        "Keshar currently leads the City Operations team at Teach For India in Pune. She relocated to India after having spent the past 25 years working in Hospitality and Tourism Management in the Middle East. With experience across sales, operations, and partnerships, Keshar brings with her a nuanced understanding of project facilitation, relationship and partner management, operational delivery and people management skills. She has held regional positions in International Leisure Sales with The Ritz-Carlton Hotel Company and General Management positions with Elegant Resorts, UK which were both entrepreneurial and Ambassadorial in nature. She has managed multicultural and diverse teams and brings with her a wealth of experience that is a welcome addition to the team in Pune."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/keshar-mokha-06124a45"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t18} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">KESHAR MOKHA</p>
                    <p className="gentxt">CITY DIRECTOR, PUNE</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("APOORV SHAH")
                      setInnModimg(t19)
                      setInnModdesig("CITY DIRECTOR, AHMEDABAD")
                      setinnModpara(
                        "Apoorv is the City Director of Ahmedabad. Prior to this role, he was a Program Manager in Ahmedabad for three years where he worked directly with Fellows and school principals. He has also worked as a School Empowerment Senior Manager in 321 Education Foundation where he trained and coached teacher trainers towards empowering teachers from low income private schools in Mumbai. He sees education as the silver bullet for a lot of societal problems and hopes that he can keep continuing to work towards making Ahmedabad and India a better place to live in for everyone."
                      )
                      setinnLinked("https://www.linkedin.com/in/apoorvshah9/")
                      setinnTweet("https://twitter.com/apoorv3e?lang=en")
                    }}
                  >
                    <img src={t19} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">APOORV SHAH</p>
                    <p className="gentxt">CITY DIRECTOR, AHMEDABAD</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("VIGNESH KRISHNAN")
                      setInnModimg(t20)
                      setInnModdesig("CITY DIRECTOR, HYDERABAD")
                      setinnModpara(
                        "Vignesh was part of the founding cohort of Teach For India Delhi, in 2011. Soon after the Fellowship, he worked on the Staff team for several years, not only serving in various program leadership roles, but also taking on increased responsibility with partnerships and operations. Vignesh led key innovations on Fellow retention and piloted various approaches to secondary instruction. He was also actively involved in contributing to various key systemic reforms initiated by the Government of Delhi. In addition, Vignesh, along with four other colleagues co-founded and revived a dysfunctional community school attached to a Madrasa (An islamic institution of learning). Vignesh holds a Master’s in Education from University College London. In 2018, he was one of the few individuals to receive the prestigious Chevening Scholarship from India. He joined the Hyderabad team as the City Director in 2020. Vignesh brings with him, a deep commitment to educational equity; a belief in the power of people development; and a nuanced understanding of Teach For India’s program and operations."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/vigneshkrishnanv/"
                      )
                      setinnTweet("https://twitter.com/vkv_iyer)")
                    }}
                  >
                    <img src={t20} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">VIGNESH KRISHNAN</p>
                    <p className="gentxt">CITY DIRECTOR, HYDERABAD</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("RAJSHREE DOSHI")
                      setInnModimg(t21)
                      setInnModdesig("DIRECTOR, TFIX")
                      setinnModpara(
                        "Rajshree has a wealth of experience from working in various spaces within education. She started working in SPJ Sadhna school in 1986 where she joined as a volunteer, and continued to work there as a teacher, while leading workshops. 10 years later, she joined Akanksha where she again started as a volunteer and went on to run her own centre for 8 years. Meanwhile, she also assisted in training other teachers, helped in curriculum development, and launched two new programs- the Akanksha Teacher Fellowship and the Service Leadership Program, for their Alumni. She has been associated with Teach For India since its inception and joined as a Program Manager in 2009 for 2 years where she supported City Directors and Senior Program Managers until 2016. Currently she leads TFIx."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t21} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">RAJSHREE DOSHI</p>
                    <p className="gentxt">DIRECTOR, TFIX</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ASHWATH BHARATH")
                      setInnModimg(t22)
                      setInnModdesig("DIRECTOR, FIRKI")
                      setinnModpara(
                        "Ashwath joined the Teach For India Fellowship in 2012. He worked as a full time teacher for two years in a Public-Private Partnership school in Pune. Post his Fellowship, he was part of the Pune City Operations team where he led the Government Relations vertical for three years. He currently leads Firki, our teacher education platform that provides a one-stop space for all teacher needs in the form of courses, resources, mentors, and learning groups."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t22} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ASHWATH BHARATH</p>
                    <p className="gentxt">DIRECTOR, FIRKI</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("KRITIKA RAWAT")
                      setInnModimg(t23)
                      setInnModdesig("COACH, KIDS EDUCATION REVOLUTION")
                      setinnModpara(
                        "Kritika graduated from Miranda House in 2015 with a degree in Political Science. During her time in college, she started teaching dance in Teach for India classrooms in Delhi and was struck by the infinite potential she saw in the children. At that moment, she decided to apply for the Teach For India Fellowship and taught the naughtiest bunch of 4th and 5th graders in Yerawada, Pune. The experience left her with a deepened belief in student voice and leadership, post which she joined the Kids Education Revolution (KER), with a dream of reimagining education with our children. Currently, she leads KER that directly impacts over 12,000 students and educators."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t23} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">KRITIKA RAWAT</p>
                    <p className="gentxt">COACH, KIDS EDUCATION REVOLUTION</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("PRAMOD PUJARI")
                      setInnModimg(t24)
                      setInnModdesig("MANAGER, ADMINISTRATION")
                      setinnModpara(
                        "Pramod has around 14 years of experience in the administrative and operations field along with handling events and providing logistics support. He has, so far, managed to handle the logistics for 12 Institute Training Programs for Fellows and Staff at Teach For India. He graduated from Mumbai University and worked with Mahindra and Mahindra before he joined Teach For India."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t24} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">PRAMOD PUJARI</p>
                    <p className="gentxt">MANAGER, ADMINISTRATION</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("FRANCIS VIDHAYATHIL")
                      setInnModimg(t25)
                      setInnModdesig("CHIEF FINANCIAL OFFICER")
                      setinnModpara(
                        "Francis Vidhayathil, who is the founding partner of FinAdvisors LLP, joined Teach For India in 2019 as its virtual CFO. His areas of expertise include management reporting, compliances, cost control, business transformation, business planning, process improvements, driving efficiencies and improving productivity. Francis’ strength lies in being a team player, business leader and coordinator of various tasks and functions. Francis is a Chartered Accountant, Cost Accountant and Company Secretary by qualification with over 20 years of rich experience in leadership roles with various MNCs, listed and private companies as well as start-ups. These include Mahindra & Mahindra, Tech Mahindra, CapGemini, UST Global, TinyOwl and DrumsFood."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t25} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">
                      FRANCIS VIDHAYATHIL
                    </p>
                    <p className="gentxt">CHIEF FINANCIAL OFFICER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("SHIVANI AGRAWAL")
                      setInnModimg(t26)
                      setInnModdesig("DIRECTOR, STRATEGY AND LEARNING")
                      setinnModpara(
                        "Shivani leads the Strategy & Learning team at Teach For India. She first joined the Teach For India Fellowship in 2013 and taught grades 4 and 5 in a government school in Delhi. She has since worked in research and social impact consulting before returning to Teach For India. She led impact evaluation while working at J-PAL South Asia, and worked for a consulting firm in New York developing inclusive finance solutions for the bottom of the pyramid in Latin America and Africa. While at the Boston Consulting Group's Social Impact Practice, she provided strategic and implementation support to state governments, multilaterals and nonprofits. Shivani has a Masters in Public Administration from Columbia University."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={t26} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">SHIVANI AGRAWAL</p>
                    <p className="gentxt">DIRECTOR, STRATEGY AND LEARNING</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("SWETHA BALAKRISHNAN")
                      setInnModimg(swethaImage)
                      setInnModdesig("DIRECTOR, RECRUITMENT")
                      setinnModpara(
                        "Swetha leads the Fellowship Recruitment team at Teach For India. She first joined the Teach For India Fellowship in 2012 and taught grades 3 and 4 in a government school in Pune. Post the Fellowship, she worked as a Program Manager in Pune, where she trained secondary Fellows and set up the secondary ecosystem of support. She went on to work as Chief of Staff where besides supporting organisational governance, she led the Kids Education Revolution team. Thereafter, she had a brief stint in impact investing with Elevar Equity before returning to Teach For India in her current capacity. Swetha holds a Masters in International Logistics Management & Engineering from Jacobs University Bremen in Germany."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={swethaImage} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">
                      SWETHA BALAKRISHNAN
                    </p>
                    <p className="gentxt">DIRECTOR, RECRUITMENT</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("PARAG JALAN")
                      setInnModimg(paragImage)
                      setInnModdesig("DIRECTOR, RECRUITMENT")
                      setinnModpara(
                        "Parag leads our partnership and collaboration efforts with city and state governments across the country. Prior to this role, Parag worked at a private foundation where he was in charge of identifying bold ideas that are ready to scale and apt for private philanthropy. He also spent two years as a management consultant at McKinsey&Company in New York and two years at the Clinton Health Access Initiative in Cambodia and Swaziland, where he helped governments bridge budgetary gaps in their health portfolio, scale healthcare related pilot programs nationally, and improve access to life-saving drugs. He started his career in finance working for BlackRock in the firm’s risk management division. Parag is a chemical engineer by training and holds an MBA from Yale School of Management."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={paragImage} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">
                      PARAG JALAN
                    </p>
                    <p className="gentxt">DIRECTOR, GOVERNMENT RELATIONS</p>
                  </div>
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <p className="ed-title text-center pt-5 bolder">
                  BOARD MEMBERS
                </p>
                <Row>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("SHAHEEN MISTRI")
                      setInnModimg(t1)
                      setInnModdesig("CEO AND FOUNDER TRUSTEE")
                      setinnModpara(
                        "Shaheen grew up in 5 countries around the world, and returned to India when she was 18 to start Akanksha. For 17 years, she worked with teachers and students, building Akanksha to provide children from low- income communities the kind of education that would maximize their greatest potential. Today, Akanksha serves 9300 children through their School Project and after-school centres in Mumbai and Pune. In 2008, Shaheen founded Teach For India, with an audacious vision of providing an excellent education to all children across India through building a pipeline of leaders committed to ending educational inequity in India. Today, Teach For India directly impacts approximately 32,000 children across seven regions in India. Shaheen has also created projects like the Maya Musical and the Kid’s Education Revolution which explore student leadership, platforms for student voice and partnership, and TFIx, which is a year-long incubator program for passionate entrepreneurs who are willing to adapt Teach For India’s model to their context and region in rural areas. Shaheen serves on the boards of the Akanksha Foundation, and Simple Education Foundation. Shaheen has been an Ashoka Fellow, a Global Leader for Tomorrow at the World Economic Forum, and an Asia Society 21 Leader. She is the author of the book, Redrawing India and the Miss Muglee Children’s Books. She has a Bachelor’s Degree from St. Xavier’s College, Mumbai and a Master’s Degree from the University of Manchester."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/shaheen-mistri/"
                      )
                      setinnTweet("https://twitter.com/shaheenmistri")
                    }}
                  >
                    <img src={t1} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">SHAHEEN MISTRI</p>
                    <p className="gentxt">CEO AND FOUNDER TRUSTEE</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("NISABA GODREJ")
                      setInnModimg(b2)
                      setInnModdesig("CHAIRPERSON AND TRUSTEE")
                      setinnModpara(
                        "Nisaba Godrej is the Chairperson and Managing Director of Godrej Consumer Products Limited (GCPL). She has been a key architect of GCPL's strategy and transformation in the last decade. She is also the Chairperson of Teach for India and sits on the board of Godrej Agrovet, Mahindra and Mahindra and VIP Industries. She has a BSc degree from The Wharton School at the University of Pennsylvania and an MBA from Harvard Business School. Nisaba is passionate about education, the human mind, trekking and equestrian sports."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={b2} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">NISABA GODREJ </p>
                    <p className="gentxt">CHAIRPERSON AND TRUSTEE</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ARNAVAZ AGA")
                      setInnModimg(b3)
                      setInnModdesig("FOUNDER TRUSTEE")
                      setinnModpara(
                        "Anu Aga retired as Chairperson of Thermax Limited, an engineering company providing systems and solutions in the areas of energy and environment, and currently serves as a Director on their Board. As Chairperson, Anu anchored the turnaround of Thermax through four major initiatives-> reconstitution of the board, shedding of non-core activities, rightsizing of operations and increased focus on the customer. Since her retirement in 2004, Anu has been focusing on social causes and is closely associated with the Thermax Foundation. Her area of interest is education and she is keenly involved with the Akanksha Foundation, an NGO promoting education for underprivileged children in Mumbai and Pune and Teach For India."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={b3} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ARNAVAZ AGA</p>
                    <p className="gentxt">FOUNDER TRUSTEE</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("NANDITA DUGAR")
                      setInnModimg(b4)
                      setInnModdesig("FOUNDER TRUSTEE")
                      setinnModpara(
                        "Nandita has over 15 years of work experience in the nonprofit education sector. She is currently on the board of the Akanksha Foundation. She was a part of the core founding team at Teach For India, and was involved in creating the strategic blueprint for its launch in India. Nandita has also served as the interim-CEO of Akanksha in 2008. Her prior experience includes working in the UK and India at the Boston Consulting Group (BCG), Unilever, and HDFC Bank. She has a Master's degree in ‘Engineering,Economics and Management’ from Oxford University."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={b4} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">NANDITA DUGAR</p>
                    <p className="gentxt">FOUNDER TRUSTEE</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("NEEL SHAHANI")
                      setInnModimg(b5)
                      setInnModdesig("FOUNDER TRUSTEE")
                      setinnModpara(
                        "Neel was a former banker with J.P.Morgan and Barclays. Before moving to Barclays, he led Sales Trading at CLSA and IIFL and has more than 25 years of experience in the industry. He is currently on the Boards of Teach To Lead and Akanksha Foundation."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={b5} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">NEEL SHAHANI</p>
                    <p className="gentxt">FOUNDER TRUSTEE</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("MEHER PUDUMJEE")
                      setInnModimg(b6)
                      setInnModdesig("TRUSTEE")
                      setinnModpara(
                        "Meher Pudumjee is the Chairperson of Thermax Ltd. Meher served as the Chairperson of Pune Zonal Council of the Confederation of Indian Industry (CII) and represented India at the Asian Business Women’s Conference in Osaka in 2006. In 2008, the World Economic Forum selected her as a Young Global Leader for her professional accomplishments, commitment to society and potential to contribute to shaping the future of the world. In 2009, she was selected CEO of the Year by Business Standard. She also serves on the boards of Imperial College India Foundation, Shakti Sustainable Energy Foundation and Pune City Connect Development Foundation. Committed to the cause of educating the underprivileged, she works closely with NGOs operating in this space. She is on the Board of Akanksha Foundation and is a Trustee of Teach to Lead, Mumbai."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={b6} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">MEHER PUDUMJEE</p>
                    <p className="gentxt">TRUSTEE</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("RAMESH SRINIVASAN")
                      setInnModimg(b7)
                      setInnModdesig("TRUSTEE")
                      setinnModpara(
                        "Ramesh Srinivasan is a Senior Partner at McKinsey & Company, and an established leader in Pharmaceutical and Organization practices. He is a Dean of the Bower Forum, McKinsey's program for CEO learning. Ramesh has extensive experience working on a broad range of strategy and organizational topics across healthcare, private equity, high-tech, banking and industrial sectors. His illustrious portfolio has seen him serve various companies on topics related to operations, marketing and research and development. These projects include merger management, organization design, leadership development, and transformational change."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={b7} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">RAMESH SRINIVASAN</p>
                    <p className="gentxt">TRUSTEE</p>
                  </div>
                </Row>
                <p className="ed-title text-center pt-5 bolder">
                  ADVISORY BOARD
                </p>
                <Row>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("CRAIG JOHNSON")
                      setInnModimg(ab1)
                      setInnModdesig("")
                      setinnModpara(
                        'Craig has been serving as the Head of the American School of Bombay (ASB) since 2010. Prior to returning to India, he served as the Principal and then Headmaster of two different international schools in Brazil. Craig was awarded the prestigious ‘Innovation in Leadership’ award this year by the Association for the Advancement of International Education, a premier global association for International Schools. He has authored numerous articles on a variety of subjects, a screenplay set in Mumbai soon to be made into a movie, and has published a novel titled "Wave Watcher".'
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab1} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">CRAIG JOHNSON</p>
                    <p className="gentxt"></p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("WENDY KOPP")
                      setInnModimg(ab2)
                      setInnModdesig("")
                      setinnModpara(
                        "Wendy Kopp is CEO and co-founder of Teach For All, a global network of independent organizations that are cultivating their nations’ promising future leaders to ensure their most marginalized children have the chance to fulfil their true potential. Wendy founded Teach For America in 1989 to marshal the energy of her generation against educational inequity in the United States. After leading Teach For America’s growth and development for 24 years, in 2013, Wendy transitioned out of the role of CEO and remains an active member of Teach For America's board. Wendy has been recognized as one of Time Magazine’s 100 Most Influential People. She serves on the Board of New Profit and is a member of the Council on Foreign Relations. She is the author of A Chance to Make History: What Works and What Doesn’t in Providing an Excellent Education for All (2011) and One Day, All Children: The Unlikely Triumph of Teach For America and What I Learned Along the Way (2000)."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab2} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">WENDY KOPP </p>
                    <p className="gentxt"></p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("DEEPAK SATWALEKAR")
                      setInnModimg(ab3)
                      setInnModdesig("")
                      setinnModpara(
                        "Mr. Deepak M. Satwalekar was the Managing Director of HDFC Ltd until 2000, and thereafter the Managing Director and CEO of HDFC Standard Life Insurance Co. Ltd until 2008. He has also been a consultant to the World Bank, the Asian Development Bank, the United States Agency for International Development (USAID) and the United Nations Human Settlements Programme (HABITAT). Deepak is a recipient of the ‘Distinguished’ Alumnus Award’ from the Indian Institute of Technology (IIT), Bombay, where he is on the Advisory Council. He is the Chairman, Board of Governors of the Indian Institute of Management, Indore, and has chaired or been a member of several industries, Governments and Reserve Bank of India expert groups."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab3} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">DEEPAK SATWALEKAR</p>
                    <p className="gentxt"></p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("KIMBERLY FERNANDES")
                      setInnModimg(ab4)
                      setInnModdesig("")
                      setinnModpara(
                        "Kim has worked in education and development for close to a decade. Their experience in the field includes teaching K-12 students and young adults, teacher training, curriculum development and monitoring and evaluation. They have masters degrees in Statistics and Measurement from the University of Pennsylvania and International Education Policy from Harvard's Graduate School of Education. Kim's current work is focused on early childhood development and disability. They are currently pursuing a joint doctoral degree in education and anthropology from the University of Pennsylvania."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab4} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">
                      KIMBERLY FERNANDES
                    </p>
                    <p className="gentxt"></p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ANIKET DOEGAR")
                      setInnModimg(ab5)
                      setInnModdesig("")
                      setinnModpara(
                        "Aniket is CEO and Co-Founder of Haqdarshak, a social enterprise that uses technology to bridge the gap between citizens, the government and private welfare schemes. Haqdarshak is present in 20 states and has reached more than 300,000 citizens as of Nov 2019. Previously, Aniket spent two years as a Teach for India Fellow teaching children of grade 2 in government municipal schools in Pune, Maharashtra. He has over 9 years of experience in the development sector and is an alumnus of the Shri Ram College of Commerce. He is also an Acumen Fellow, 2018 and was recognized as one of the Forbes 30 under 30 Asia, 2019."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab5} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ANIKET DOEGAR</p>
                    <p className="gentxt"></p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("KRISHNAN SUBBARAMAN")
                      setInnModimg(ab6)
                      setInnModdesig("")
                      setinnModpara(
                        "Krishnan has been part of the management team at Central Square Foundation (CSF) since 2015 where he has worked at the intersection of governments, non-profits, corporates and researchers to drive systemic reform in India’s school education sector. In his current role, he builds and supports an ecosystem of education non-profits and aims to accelerate their journey towards impact, scale and sustainability. Krishnan transitioned from a corporate banking job to the development sector through the Teach for India fellowship, where he taught fourth and fifth graders in a public school in Chennai. Krishnan holds an MBA in Finance and Economics from XLRI Jamshedpur and an undergraduate degree in engineering from NIT Trichy."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab6} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">
                      KRISHNAN SUBBARAMAN
                    </p>
                    <p className="gentxt"></p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("PRIYANKA PATIL")
                      setInnModimg(ab7)
                      setInnModdesig("")
                      setinnModpara(
                        "Priyanka is an upcoming senior at Franklin and Marshall College in the USA, where she studies Psychology, Theater and Italian. She was a student at a Teach For India arts program called Maya in 2012 which opened up doors for her to pursue higher education abroad and put her on the quest to finding ways of fulfilling her potential and making the world a better place. Priyanka comes from a slum community in Pune and travelling around the world from Italy where she completed her IB diploma to now the US, she has come to recognize the undeniable value of Education and Arts in flourishing the human heart and fighting the unjust evils of inequity."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab7} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">PRIYANKA PATIL</p>
                    <p className="gentxt"></p>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("JYOTI CHAUHAN")
                      setInnModimg(ab8)
                      setInnModdesig("")
                      setinnModpara(
                        "Jyoti is a Grade 12 Student pursuing humanities in Delhi Public School, Faridabad. She loves reading, writing, travelling, listening to music, meditation, learning, studying. She is also a spoken word poet and runs a project called GURUKUL which aims to integrate art in education. The project began in November 2018. Since then, she has worked with 25 artisits, and impacted more than 500 children across India through it."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab8} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">JYOTI CHAUHAN</p>
                    <p className="gentxt"></p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ANURAG KUNDU")
                      setInnModimg(ab9)
                      setInnModdesig("")
                      setinnModpara(
                        "Anurag is a Joint Secretary, Government of India rank official currently working as Chairperson, Delhi Commission For Protection of Child Rights (DCPCR), a statutory body of Government of Delhi to monitor the implementation of rights of the children. His responsibilities include supervising eight divisions responsible for monitoring the implementation of the rights of the children, commission research studies, inquire into specific complaints/violations of the rights, and advise the Government on policy formulation and amendment. Prior to this, he also worked on Delhi's Education Task Force and with Indus Action. Anurag is a 2013-15 Teach For India Fellow."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab9} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ANURAG KUNDU</p>
                    <p className="gentxt"></p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("PRAMATH RAJ SINHA")
                      setInnModimg(ab10)
                      setInnModdesig("")
                      setinnModpara(
                        "Pramath is a pioneering force in Indian higher education. He is the Founder & Chairman of Harappa Education, which strives to become India’s largest online institution focused on teaching habits and skills critical to workplace success in the 21st century. He is the Founding Dean and a member of the Executive Board of ISB which rapidly became one of the top-20 business schools in the world. He is also the Founder & Trustee of the acclaimed Ashoka University, a liberal arts university which launched the popular Young India Fellowship. Pramath has been instrumental in setting up a wide spectrum of change-based higher education initiatives, including a management program for career-oriented women, an entrepreneurship fellowship for the Himalayan region, and a solution-focused design education for the built environment. He has also been a media entrepreneur, education consultant and management advisor at the 9.9 Group, which he founded, the CEO of the Ananda Bazar Patrika Group, and a Partner at McKinsey & Company. Academics are his first love: He received a PhD and an MSE from the University of Pennsylvania and a BTech from the Indian Institute of Technology, Kanpur, where he was honored with the Distinguished Alumnus Award in 2018."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ab10} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">PRAMATH RAJ SINHA</p>
                    <p className="gentxt"></p>
                  </div>
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="third">
                <p className="ed-title text-center pt-5 bolder">BANGALORE</p>
                <Row>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ARNAVAZ AGA")
                      setInnModimg(ban1)
                      setInnModdesig("CHAIRPERSON AND MEMBER")
                      setinnModpara(
                        "Anu Aga retired as Chairperson of Thermax Limited, an engineering company providing systems and solutions in the areas of energy and environment, and currently serves as a Director on their Board. As Chairperson, Anu anchored the turnaround of Thermax through four major initiatives-> reconstitution of the board, shedding of non-core activities, rightsizing of operations and increased focus on the customer. Since her retirement in 2004, Anu has been focusing on social causes and is closely associated with the Thermax Foundation. Her area of interest is education and she is keenly involved with the Akanksha Foundation, an NGO promoting education for underprivileged children in Mumbai and Pune and Teach For India."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ban1} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ARNAVAZ AGA</p>
                    <p className="gentxt">CHAIRPERSON AND MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("K. VAIJAYANTI")
                      setInnModimg(ban2)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Vaijayanti heads the Resource and Research group at the Akshara Foundation. A trained economist, she holds an M.Phil in Applied Economics from the Centre for Development Studies, JNU. She has worked in the health sector and decentralisation prior to venturing into the education field. In education she has extensively worked on issues ranging from Early Childhood education to School education in the Indian context. Most of the research works have been evidence based policy research in education sector. She also heads ASER- Karnataka since 2009. She has been a member of several committees set up by the Government of Karnataka on Elementary education, Human Development and ECCE. Vaijayanti has also anchored the preparation of NCF for ECCE- Karnataka."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ban2} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">K. VAIJAYANTI</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("PAVITHRA K.L.")
                      setInnModimg(ban3)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Pavithra joined Dream a Dream’s life skills programme as a young adolescent girl from a background similar to many Teach For India Children. She was part of the mentoring programme and other life skills initiatives. At the age of 16, she joined as an intern and has grown within Dream a Dream since then. Currently, she works as Associate Director, Program. Pavithra is passionate about working with young people and believes in creating the support systems for young people that she didn’t have access to. She completed her graduation in commerce from Bangalore University while working with Dream a Dream. A multi-tasker, she has donned different roles across projects and has proven her ability to adapt herself to a challenging work environment. Currently, she works as Manager – Career Connect Programme where she is responsible for ensuring and engaging with over 5000 young people along with a 15-member team, developing various skill development modules for young people. Her mission is to work with young people and transform their life using life skills approach. Pavithra also works as a Lead Facilitator training teachers/community workers across Karnataka as part of the Teacher Development Programme."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ban3} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">PAVITHRA K.L.</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("SHASHI NAIR")
                      setInnModimg(ban4)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Shashi is a director at Viridus Social Impact Solutions, Programme Director at NILE, Advaith Foundation and a member of the Academic Working Group at Indian School of Development Management. He is a B. Tech from IIT Madras, and an MBA from IIM Bangalore. He has over 27 years of experience in the corporate sector, including 14 years as a CEO. He has over 11 years of experience in the social sector, both as a practitioner and an academic in the area of systemic educational change. Shashi is devoted to a strong public school education system in India, and is deeply interested in the diagnosis, design and strengthening of the same. He explores and disseminates holistic, systemic approaches to strengthen the education system, with a focus on small geographies such as clusters, blocks and districts. Shashi is committed to the idea of networks of critical stakeholders in the country tapping into bodies of knowledge to drive educational change. From 2006 to 2011, in Azim Premji Foundation, he was responsible for creating and implementing strategies for: large scale development of leadership capabilities of education functionaries in Karnataka; and systemic capacity development of the district elementary education system in Mandya, Karnataka. From 2011 to 2017, in Azim Premji University, Shashi has been designing, developing, and facilitating Master’s level courses on educational change, school systems analysis and program design and evaluation in education. He has also been accessing, creating and disseminating bodies of knowledge on educational change in India through outreach programmes. Currently, Shashi is: a founder director of Viridus Social Impact Solutions; Programme Director – NILE, Advaith Foundation; Member – Academic Working Group, and Faculty, Indian School of Development Management. Shashi is also on the advisory board of a few NGOs."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ban4} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">SHASHI NAIR</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("TEJAS GOENKA")
                      setInnModimg(ban5)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Tejas Goenka is the Executive Director at Tally Solutions. He was inducted into Tally in 2010 while he still had a year remaining at the University of Pennsylvania. In 2011, he completed his Mechanical Engineering from the University and joined Tally on a full-time basis. Since the time Tejas joined the business, he has focused his efforts on bringing in greater operational effectiveness across the organisation. He has led the biggest transformation of Tally's selling and servicing partner network that comprises of over 25000 partners to a more accountable, higher quality network that has resulted in much more aggressive business growth. His interest in emerging markets and their economic potential has also led to the business investing in and expanding into the Middle East, Africa,and South East Asia. At Tally, Tejas sees a tremendous opportunity to positively impact the lives of millions of entrepreneurs and billions of individuals across the globe. It is with this intent that he desires to grow the business globally."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ban5} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">TEJAS GOENKA</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("TULIKA VERMA")
                      setInnModimg(ban6)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Tulika took over city leadership for Bangalore with a deep belief in the collective power and potential of committed, passionate individuals. Prior to Teach for India, she has worked with Philps Healthcare across healthcare product portfolios, and has done a brief stint leading a state government innovation project. She has an MBA from IIM, Ahmedabad and a B.Tech from Manipal Institute of Technology."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ban6} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">TULIKA VERMA </p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("V RAVICHANDAR")
                      setInnModimg(ban7)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Ravichandar is Chairman, Feedback Consulting. His work focus is on guiding the firm for sustained growth by providing increasing value to clients. He is Honorary Consul, Republic of Slovenia for Bengaluru. Ravichandar was a key member of the Bangalore Agenda Task Force (BATF) during 2000-04. Ravichandar along with three others was involved in making the case for Jawaharlal Nehru National Urban Renewal Mission (JN-NURM). Since 2006 he has been evangelizing the idea of City Connect (now in 5 cities) involving business, civil society and government. He was part of the 3 member Government Expert Committee tasked with reimaging governance and administration for Bengaluru. He was Co-Chair of the Karnataka Tourism Vision Group. Ravichandar is part of multiple social and cultural initiatives – Bangalore International Centre; Bangalore Literature Festival; Education for the underprivileged through Vidya Poshak, Samhita Academy, Akshara Foundation; Takshashila Institution; Citizen Matters, etc. Ravichandar has a Mechanical Engineering degree from the Birla Institute of Technology and Science, Pilani and an MBA from the Indian Institute of Management, Ahmedabad with over 38 years industry experience."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={ban7} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">V RAVICHANDAR</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                </Row>
                <p className="ed-title text-center pt-5 bolder">DELHI</p>
                <Row>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("TARA SINGH VACHANI")
                      setInnModimg(del1)
                      setInnModdesig("CHAIRPERSON AND MEMBER")
                      setinnModpara(
                        "Tara Singh Vachani is the Executive Chairman of Antara Senior Living and is on the Board of Max India Ltd as Non – Executive Director. Tara is also a Director at Max Healthcare and Leeu Collection, her family’s international hospitality business in U.K., South Africa and Italy. Tara is also the Managing Trustee of Max India Foundation, which represents Max Group’s social responsibility efforts. Tara has majored in Politics and South Asian studies at the National University of Singapore followed by courses in Strategy Management at the London School of Economics, and Hospitality Business Strategy and Management at Ecole hotelier de Lausanne, Switzerland."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={del1} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">
                      TARA SINGH VACHANI
                    </p>
                    <p className="gentxt">CHAIRPERSON AND MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("AAKANKSHA GULATI")
                      setInnModimg(del2)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Aakanksha currently works at ACT Grants where she leads their education grants and is building ACT as a platform to catalyse social sector innovations and entrepreneurs to create lasting impact at scale. Prior to this, Aakanksha worked at Teach For India for six years as Chief Program Officer and City Director, Delhi, where she led strategy and program operations, which included supporting Fellowship recruitment and Alumni impact. Before transitioning to the education sector, she worked at The Boston Consulting Group (BCG) on projects spanning credit strategy, organizational transformation, pre-merger planning and strategic planning for various banking sector clients. She has also worked in New York with Keefe, Bruyette & Woods as an Assistant Vice President and with Lehman Brothers (later Barclays Capital) as an Analyst on the Securitization and Mortgage Trading Desk. Aakanksha was an F.C. Austin Scholar at the Kellogg School of Management where she completed her MBA and graduated magna cum laude with a double Bachelor’s degree in Mathematics and Economics from Duke University. She believes that anyone who is interested in cross-disciplinary and innovative approaches to problem-solving ought to consider working in the Education sector."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={del2} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">AAKANKSHA GULATI</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("AKHIL SIBAL")
                      setInnModimg(del3)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Akhil Sibal is a Senior Advocate. After living for three years in Egypt, Akhil completed his International Baccalaureate in Paris. He proceeded to Stanford University, where be obtained a BA in Philosophy and Political Science. He then read law at the University of Cambridge, and returned to Delhi in 2002 to begin an independent litigation practice. Akhil was designated as Senior Advocate by the Delhi High Court in 2017. He has a varied legal practice across both civil and criminal law. His areas of focus include commercial arbitration and intellectual property disputes."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={del3} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">AKHIL SIBAL</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("AMITAV VIRMANI")
                      setInnModimg(del4)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Amitav is the Founder and CEO of The Education Alliance, a non-profit organization focused on improving the quality of education in Government schools. TEA works with the state government of Tamil Nadu, Madhya Pradesh and the South Delhi Municipal Corporation. Prior to founding The Education Alliance, Amitav was Country Director, ARK India, a UK based NGO. Amitav and his team launched several programmes focused on school leadership, english language literacy, school vouchers and school quality assesments. Amitav worked for Bristol Myers Squibb based in Princeton for 5 years. He has also been involved with his family-run business in Punjab since 1994. Amitav was educated at the Doon School and went on to study Economics at St. Stephens College, Delhi University. He has an MBA from UNC Chapel Hill, USA. He sits on the boards of AVPN India, Teach for India (Delhi), CAPED, Blind School, Childrens Book Trust, Virmani Education Trust and The Shri Ram Schools. Amitav is a member the International Board of Advisors for Kenan Flagler Business School, UNC, Chapel Hill. In 2010, he became an Aspen Fellow, in 2015 he became an Ashoka Fellow and 2017 an N/Core Fellow."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={del4} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">AMITAV VIRMANI</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ANU PRASAD")
                      setInnModimg(del5)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Anu Prasad is the Founder-Director of India Leaders for Social Sector, a section 8 non profit in the space of leadership, learning and capacity building for the spocial sector. Prior to this, Anu was one of the founding members of Ashoka University and ran it's flagship post graduate program, the Young India Fellowship (YIF). In her corporate career, she was a member of the team at American Express in India that led the implementation of Amex’s global financial re-engineering, helping to create the Financial Resource Centre in New Delhi. Post-Amex, she worked as the Regional Head of Finance for TNT, North India. An entrepreneur at heart, she also co-founded and managed a travel company in India. She also worked as a consultant with the Michael and Susan Dell Foundation."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={del5} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ANU PRASAD</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("MANSI JOSHI")
                      setInnModimg(del6)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Mansi is currently leading the City Operations team at Teach For India in Delhi. She has completed her Master’s in Business Administration from the Indian School of Business and holds an Economics (H) degree from the University of Delhi. Prior to this, she has worked as a Senior Program Manager and Program Manager at Teach For India, Delhi. Her work involved designing Strategy for the Delhi Program and driving implementation by working with the Program Team to uphold Fellow, Student and Staff development across the city. Mansi was a 2012 Fellow and taught 40 girls in grade 2 and 3 in Majnu Ka Tila in Delhi."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={del6} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">MANSI JOSHI </p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("TARUN CHERUKURI")
                      setInnModimg(del7)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Tarun is founder and CEO at Indus Action. Indus Action works with students, parents, educators, schools, and governments in 20 states to implement Section 12(1)(c) of the Right to Education (RTE) law which mandates 25% reservation for disadvantaged kids in private classrooms and improve outcomes under Sec. 4(b) of the Food Security Act on maternity benefits through the Pradhan Mantri Matru Vandana Yojana (PMMVY). Tarun is also part of the 2019 cohort of the Obama Foundation Fellowship."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={del7} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">TARUN CHERUKURI</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                </Row>
                <p className="ed-title text-center pt-5 bolder">PUNE</p>
                <Row>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("MEHER PUDUMJEE")
                      setInnModimg(pun1)
                      setInnModdesig("CHAIRPERSON AND MEMBER")
                      setinnModpara(
                        "Meher Pudumjee is the Chairperson of Thermax Ltd. Meher served as the Chairperson of Pune Zonal Council of the Confederation of Indian Industry (CII) and represented India at the Asian Business Women’s Conference in Osaka in 2006. In 2008, the World Economic Forum selected her as a Young Global Leader for her professional accomplishments, commitment to society and potential to contribute to shaping the future of the world. In 2009, she was selected CEO of the Year by Business Standard. She also serves on the boards of Imperial College India Foundation, Shakti Sustainable Energy Foundation and Pune City Connect Development Foundation. Committed to the cause of educating the underprivileged, she works closely with NGOs operating in this space. She is on the Board of Akanksha Foundation and is a Trustee of Teach to Lead, Mumbai."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={pun1} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">MEHER PUDUMJEE</p>
                    <p className="gentxt">CHAIRPERSON AND MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("ANSHOO GAUR")
                      setInnModimg(pun2)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Anshoo is a global tech executive, entrepreneur, board member with 30 years of tech experience. He is also an expert in restructuring businesses for growth and scale. A true believer of the incredible potential of technology and innovation, he has worked in several senior management positions through his career. Recent notable associations include; Amdocs (Global management member, Global Officer), EDS (India MD, President Mphasis ITO), STL (Advisor, CEO Software). Anshoo has been associated with several promising early-stage companies through his start-up accelerator, Praavega, and is active in the social sector helping scale impact. As the Founding Curator of WEF’s Global Shaper Community’s Pune Hub, Anshoo works with youth leaders committed to driving social change. A believer in the power of mentoring in helping transform lives, Anshoo is actively engaged with several social enterprises, NGOs, and serves as an Advisor to Mentor Together, India’s largest youth mentoring network. Anshoo is the winner of several awards including Bloomberg-UTV CXO IT MNC Business Leader of the Year Award in 2010. He holds a post-graduate degree in Industrial and Systems Engineering from the University of Arizona, Tucson and a graduate degree in Mechanical Engineering from National Institute of Technology, Surathkal, India."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={pun2} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">ANSHOO GAUR</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("JOSEPH CUBAS ")
                      setInnModimg(pun3)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Joseph Cubas is the Head of School at Avsara Academy. He is currently the Director of Institutional Advancement at the American School of Bombay. In his role, he works with the Superintendent to support and facilitate the work of the Board of Trustees. He oversees the school’s print and media communications and partners with the Director of Technology to manage the school’s online presence. He also oversees the development and implementation of the school’s Strategic Plan and advises the Leadership Team on the development of systems and processes across all functional areas of the school. Previously, Joseph served as Special Assistant to a Superintendent of public schools in New York City where he assisted the Superintendent and her team in the conception and implementation of various region-wide initiatives. Joseph holds a BA in American Studies from Yale University and an MA in Latin American Cultural Politics from the University of London."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={pun3} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">JOSEPH CUBAS </p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("KESHAR MOKHA")
                      setInnModimg(t18)
                      setInnModdesig("CITY DIRECTOR, PUNE")
                      setinnModpara(
                        "Keshar currently leads the City Operations team at Teach For India in Pune. She relocated to India after having spent the past 25 years working in Hospitality and Tourism Management in the Middle East. With experience across sales, operations, and partnerships, Keshar brings with her a nuanced understanding of project facilitation, relationship and partner management, operational delivery and people management skills. She has held regional positions in International Leisure Sales with The Ritz-Carlton Hotel Company and General Management positions with Elegant Resorts, UK which were both entrepreneurial and Ambassadorial in nature. She has managed multicultural and diverse teams and brings with her a wealth of experience that is a welcome addition to the team in Pune."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/in/keshar-mokha-06124a45"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={pun4} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">KESHAR MOKHA</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("KUMAR GERA")
                      setInnModimg(pun5)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Kumar Gera, a stalwart of the profession, leads Gera Developments Pvt. Ltd. as its Chairman. Forty years of hard work, perseverance and a commitment to real estate have allowed him to realize his vision of turning his family-run business into a brand name that is synonymous with professionalism, quality and respectability. Kumar Gera is the Founder President of the Confederation of Real Estate Developers' Associations of India (CREDAI) and was instrumental in conceptualizing and creating CREDAI as a common platform for all real estate developers across India. He was the chairman CREDAI (1995-2005) Served as member of the State Planning Board Government of Maharashtra (1998-2000). He was also a President PBAP (1987-1995), a VP FPBAM (1996-1997) and a member of the task force for the National Housing Policy Government of India (2005-2006)."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={pun5} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">KUMAR GERA</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("PRADEEP BHARGAVA")
                      setInnModimg(pun6)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Mr Pradeep Bhargava was working as the Managing Director of Cummins Generator Technologies India Limited from May 2003 till Apr 2012. He has steered the growth of the organization, made it a market leader and a major global player. A veteran in the industry, Mr Bhargava has held responsible positions in both Private and Public Sector organiations. His tenure in the State sector spanned a decade, during which he worked with ISRO, Atomic Energy Commission, Electronics Commission, BHEL. He also had a tenure with UNDP and World Bank. Mr Bhargava was the Executive Member of National Assessment and Accreditation Council( NAAC)."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={pun6} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">PRADEEP BHARGAVA </p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("RATI FORBES")
                      setInnModimg(pun7)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Rati is a Director at Forbes Marshall Ltd - a company which has been, for over six decades, building steam engineering and control instrumentation solutions that work for the process industry. Rati is responsible for the Social Initiatives which the company supports; in the areas of community health, education and women's issues. She has also been instrumental in setting up and heading the Foundation arm of the company. She is a graduate in Psychology and Sociology; has done courses in Human Resource Management from IIM Ahmedabad; and more recently, on Philanthropy and Social Entrepreneurship from Stanford University. She has just completed a Masters degree in Sustainability Leadership and Management, from Cambridge, UK."
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={pun7} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">RATI FORBES</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                  <div
                    className="col-lg-3 col-md-4 col-6 team-profile py-4"
                    onClick={() => {
                      setInnMod2("active show")
                      setinnModTitle("SIDDESH SARMA")
                      setInnModimg(pun8)
                      setInnModdesig("MEMBER")
                      setinnModpara(
                        "Siddesh is the Co-Founder and Chief Programs Officer at Leadership For Equity (LFE). He has an M.Phil in education from TISS Mumbai, has represented SCERT, Maharashtra on two NCERT Committees, and has worked for the past 5 years consulting with the School Education Department, Govt. of Maharashtra. Siddesh started his work in education through Teach For India Fellowship in 2011 and has worked as an Curriculum Writer for Bridge International Academies. He also serves on the Advisory Board of Teach For India, Pune"
                      )
                      setinnLinked(
                        "https://www.linkedin.com/company/teach-for-india/"
                      )
                      setinnTweet("https://twitter.com/TeachForIndia")
                    }}
                  >
                    <img src={pun8} className="w-100" />
                    <p className="fi-txt bolder mb-0 pt-3">SIDDESH SARMA</p>
                    <p className="gentxt">MEMBER</p>
                  </div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </Container>

      <div className={`model ${innMod2}`}>
        <div className="model-body finmod">
          <div className="modelClose" onClick={() => setInnMod2("")}>
            <img src={close} />
          </div>
          <Container>
            <Row>
              <div className="col-lg-3 col-md-4 col-6 team-profile">
                <img src={innModimg} className="w-100" />
                <p>
                  {/* <a href={innTweet} target="_blank" rel="noreferrer noopener">
                    <img src={twitter} className="modSocIcon" />
                  </a> */}
                  <a href={innLinked} target="_blank" rel="noreferrer noopener">
                    <img src={linkedin} className="modSocIcon" />
                  </a>
                </p>
              </div>
              <div className="col-lg-9">
                <p className="jtf-head mb-0">{innModTitle}</p>
                <p className="ed-title mb-3">{innModdesig}</p>
                <p className="fi-txt">{innModpara}</p>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default OurPeople
